import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Img from "gatsby-image"
import BlockContent from "@sanity/block-content-to-react"

import Layout from '../../layouts'

const PageMogelijkheden = ({ data }) => {
  const page = data.page
  return (
  <Layout>
    <div id="start" className="panel content">
      <Helmet>
        <title>Mogelijkheden - Sureventure Camperbouw</title>
        <meta name="description" content={page.metaDescription} />
      </Helmet>
      <section>
        <div className="content-text">
          {page.title &&
            <h1>{page.title}</h1>
          }
          {page._rawIntro &&
            <div className="intro">
              <BlockContent blocks={page._rawIntro} />
            </div>
          }
          {page._rawBody &&
            <BlockContent blocks={page._rawBody} />
          }
        </div>

        {page.services && page.services.map(function(service, i){
          return (
              <div key={i} className="mogelijkheden-item row order-odd">
                <div className="mogelijkheden-item__content medium-8 columns content-text">
                  <h2 className="mogelijkheden-item__content__title">{service.title}</h2>
                  {service._rawDescriptionShort &&
                    <BlockContent blocks={service._rawDescriptionShort} />
                  }
                </div>
                <div className="mogelijkheden-item__image medium-4 columns content-img">
                  {service.imageList &&
                    <Img fluid={service.imageList.asset.fluid} alt={service.title} />
                  }
                </div>
              </div>
          );
        })}
      </section>
    </div>
  </Layout>
)}

export default PageMogelijkheden

export const query = graphql`
  query {
    page: sanityPageServices {
      title
      metaDescription
      _rawIntro
      _rawBody
      services {
        title
        _rawDescriptionShort
        imageList {
          asset {
            fluid(maxWidth: 2000) {
              ...GatsbySanityImageFluid_withWebp
            }
          }
        }
      }
    }
  }
`

